import { Injectable } from '@angular/core';

import {
  Observable,
  combineLatest,
  switchMap,
  BehaviorSubject,
  map,
  shareReplay,
  lastValueFrom,
} from 'rxjs';
import { IsoDateTime, LineStringGeometry } from '@cartken/map-types';
import { BackendService } from '../core/backend.service';
import { ErrorService } from '../core/error-system/error.service';
import * as v from 'valibot';
import { vParsePretty } from '@/utils/valibot-parse-pretty';

export const MapRecordingMetadataSchema = v.object({
  robotName: v.string(),
  hash: v.string(),
  polyline: v.optional(LineStringGeometry),
  startAt: IsoDateTime,
  durationMillis: v.number(),
  trajectoryLength: v.number(),
});

export type MapRecordingMetadataDto = v.InferOutput<
  typeof MapRecordingMetadataSchema
>;
export type MapRecordingMetadataDtoSortableKeys = Exclude<
  keyof MapRecordingMetadataDto,
  'polyline'
>;

const DEFAULT_PAGE_SIZE = 100;
const INITIAL_PAGE_INDEX = 0;

@Injectable()
export class MapRecordingsDataService {
  readonly pageIndex$ = new BehaviorSubject<number>(INITIAL_PAGE_INDEX);
  readonly pageSize$ = new BehaviorSubject<number>(DEFAULT_PAGE_SIZE);
  readonly sortBy$ = new BehaviorSubject<MapRecordingMetadataDtoSortableKeys>(
    'hash',
  );
  readonly sortDirection$ = new BehaviorSubject<'asc' | 'desc'>('desc');

  metadataFiles$: Observable<MapRecordingMetadataDto[]>;

  resultsLength$: Observable<number>;

  constructor(
    private backendService: BackendService,
    private errorService: ErrorService,
  ) {
    const metadataResponse = combineLatest([
      this.pageIndex$,
      this.pageSize$,
      this.sortBy$,
      this.sortDirection$,
    ]).pipe(
      switchMap(([pageIndex, pageSize, sortBy, sortDirection]) => {
        const query = [
          `sort_by=${sortBy}`,
          `sort_direction=${sortDirection}`,
          `page=${pageIndex}`,
          `per_page=${pageSize}`,
        ].join('&');
        const uri = `/map-recordings/metadata?${query}`;
        return this.backendService.getWithHeader(uri);
      }),
      shareReplay(1),
      this.errorService.handleStreamErrors('Failed to load metadata'),
    );

    this.metadataFiles$ = metadataResponse.pipe(
      map((response) => {
        return response.body.flatMap((item: object) => {
          try {
            const val = vParsePretty(MapRecordingMetadataSchema, item);
            return [val];
          } catch (e) {
            console.error('Failed to parse metadata', e, item);
            return [];
          }
        }) as MapRecordingMetadataDto[];
      }),
    );
    this.resultsLength$ = metadataResponse.pipe(
      map((response) => {
        return Number.parseInt(response.headers.get('X-Total-Count') ?? '0');
      }),
    );
  }

  async downloadContent(metadata: MapRecordingMetadataDto): Promise<void> {
    const signedUrl = await lastValueFrom(
      this.backendService.get<string>(
        `/map-recordings/content?hash=${metadata.hash}`,
      ),
    );
    window.open(signedUrl, '_blank');
  }
}
