import {
  AfterViewInit,
  Component,
  ElementRef,
  inject,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { v } from '@/valibot';
import {
  of,
  Subject,
  Observable,
  EMPTY,
  combineLatest,
  merge,
  fromEvent,
  firstValueFrom,
} from 'rxjs';
import {
  exhaustMap,
  retry,
  map,
  switchMap,
  tap,
  shareReplay,
  takeUntil,
  filter,
  debounceTime,
  distinctUntilChanged,
} from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import {
  CreateOrderDialogComponent,
  CreateOrderDialogData,
} from '../create-order-dialog/create-order-dialog.component';
import { AuthService } from '@/app/core/auth.service';
import { HandoverType, Order, OrderStatus } from '@/app/core/order/order';
import { BackendService } from '@/app/core/backend.service';
import { Operation, RobotQueueEdgeHandover } from '../operation';
import { Robot } from '@/app/core/robots-service/backend/robot.dto';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';

import { OrderDetailsDialogComponent } from '@/app/orders/order-details-dialog/order-details-dialog.component';
import {
  MatSnackBar,
  MatSnackBarRef,
  SimpleSnackBar,
} from '@angular/material/snack-bar';
import { ErrorService } from '@/app/core/error-system/error.service';

import {
  OrderManagementDialogComponent,
  hasOrderManagementAccess,
} from '../order-management-dialog/order-management-dialog.component';
import { Watchdog } from './watchdog';
import { RobotInfo } from './robot-info';
import { visiblePageTimer } from '@/utils/page-visibility';
import {
  CancelOrderDialogComponent,
  CancelOrderDialogData,
  CancelOrderDialogOutput,
} from './cancel-order-dialog.component';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, MatSortHeader } from '@angular/material/sort';
import {
  MatTableDataSource,
  MatTable,
  MatColumnDef,
  MatHeaderCellDef,
  MatHeaderCell,
  MatCellDef,
  MatCell,
  MatHeaderRowDef,
  MatHeaderRow,
  MatRowDef,
  MatRow,
} from '@angular/material/table';

import {
  hasCancelOrderAccess,
  hasCreateOrderAccess,
  hasRobotLockAccess,
  OrderTableRow,
  orderToTableRow,
} from './operation-live-view-utils';
import {
  ZoomPanTriggerEvent,
  RobotOrderMapComponent,
} from './robot-order-map.component';
import { CompartmentsDialogComponent } from '@/app/core/compartments-dialog/compartments-dialog.component';
import { HttpResponse } from '@angular/common/http';
import { AsyncPipe } from '@angular/common';
import { MatToolbar } from '@angular/material/toolbar';
import {
  MatButton,
  MatIconButton,
  MatMiniFabButton,
} from '@angular/material/button';
import { MatMenuTrigger, MatMenu, MatMenuItem } from '@angular/material/menu';
import { MatIcon } from '@angular/material/icon';
import { SplitComponent, SplitAreaComponent } from 'angular-split';
import {
  MatFormField,
  MatLabel,
  MatSuffix,
} from '@angular/material/form-field';
import { MatSelect, MatSelectTrigger } from '@angular/material/select';
import { MatOption } from '@angular/material/core';
import { BatteryStatusComponent } from '@/app/core/battery-status/battery-status.component';
import { MatInput } from '@angular/material/input';
import { CommunicationLogOverlayComponent } from '../communication-log-overlay/communication-log-overlay.component';
import { MatTooltip } from '@angular/material/tooltip';
import { PrettyTimePipe } from '@/app/core/pipes/pretty-time.pipe';
import { vParsePretty } from '@/utils/valibot-parse-pretty';
import { environment } from '@/environments/environment';
import Keycloak from 'keycloak-js';

const POLLING_INTERVAL_MS = 5 * 1000;
const POLLING_DEAD_INTERVAL_MS = POLLING_INTERVAL_MS * 3;

@Component({
  selector: 'app-desktop-operation-live-view',
  templateUrl: './desktop-operation-live-view.component.html',
  styleUrl: './desktop-operation-live-view.component.sass',
  imports: [
    MatToolbar,
    MatButton,
    MatIconButton,
    MatMenuTrigger,
    MatIcon,
    MatMenu,
    MatMenuItem,
    RouterLink,
    SplitComponent,
    SplitAreaComponent,
    RobotOrderMapComponent,
    MatFormField,
    MatLabel,
    MatSelect,
    MatSelectTrigger,
    MatOption,
    BatteryStatusComponent,
    MatMiniFabButton,
    MatInput,
    MatSuffix,
    MatTable,
    MatSort,
    MatColumnDef,
    MatHeaderCellDef,
    MatHeaderCell,
    MatSortHeader,
    MatCellDef,
    MatCell,
    CommunicationLogOverlayComponent,
    MatTooltip,
    MatHeaderRowDef,
    MatHeaderRow,
    MatRowDef,
    MatRow,
    MatPaginator,
    AsyncPipe,
    PrettyTimePipe,
  ],
})
export class DesktopOperationLiveViewComponent
  implements AfterViewInit, OnDestroy
{
  private readonly keycloak = inject(Keycloak);
  private destroy$: Subject<void> = new Subject();

  private readonly minimumDisplayedColumns: string[] = [
    'robot',
    'compartment',
    'id',
    'created',
    'testOrder',
    'pickup',
    'dropoff',
    'status',
    'icons',
  ];

  operationDisplayName$!: Observable<string>;

  displayedColumns: string[] = [...this.minimumDisplayedColumns];

  readonly orderStatuses = Object.values(OrderStatus);
  orderStatusFilter = 'active';
  @ViewChild('textSearchInput') textSearchInput!: ElementRef;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  highlightItemId = '';
  focusItemId = '';

  resultsLength = 0;
  pageSizeOptions: number[] = [10, 25, 100];
  selectedPickupHandoverAlias?: string;
  pickups?: RobotQueueEdgeHandover[];
  dropoffs?: RobotQueueEdgeHandover[];
  preferredCountryCodes?: string[];

  operationId$!: Observable<string>;
  operation$: Observable<Operation>;
  operationDisplayName?: string;
  orders: Order[] = [];
  orderQueue = new MatTableDataSource<OrderTableRow>([]);
  readyForOrders = false;
  robotFilter: 'robots-with-order' | 'robots-without-order' | '' = '';

  robots: Robot[] = [];
  robotInfos: RobotInfo[] = [];
  filteredRobotInfos: RobotInfo[] = [];

  robotsReady = 0;
  robotsCount = 0;

  readonly waitingOrderStatuses = [OrderStatus.WAITING_FOR_HANDOVER];

  private refresh$ = new Subject<void>();

  canEditOrderStatus$: Observable<boolean>;
  canOpenCloseRobots$: Observable<boolean>;
  canCreateOrders$: Observable<boolean>;
  canCancelOrders$: Observable<boolean>;

  private watchdog = new Watchdog(POLLING_DEAD_INTERVAL_MS);

  constructor(
    private backendService: BackendService,
    private dialog: MatDialog,
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private orderDialog: MatDialog,
    private errorService: ErrorService,
    private snackBar: MatSnackBar,
  ) {
    this.operationId$ = this.route.paramMap.pipe(
      switchMap((params) => {
        const operationId = params.get('operation-id');

        if (operationId === null) {
          this.errorService.reportError(
            `URL is not correct, operation-id is not found`,
          );
          return EMPTY;
        }

        return of(operationId);
      }),
    );

    this.operationDisplayName$ = this.getOperationDisplayName();

    const pickupDisplayName$ = this.route.paramMap.pipe(
      switchMap((params) => {
        const pickupDisplayName = params.get('pickup-handover-alias');

        if (pickupDisplayName === null) {
          return of(undefined);
        }

        return of(pickupDisplayName);
      }),
    );

    this.operation$ = this.operationId$.pipe(
      switchMap((operationId) =>
        this.backendService
          .get<Operation>(`/operations/${operationId}`)
          .pipe(
            this.errorService.handleStreamErrors(
              `Operation with name '${operationId}' could not be retrieved.`,
            ),
          ),
      ),
      tap((operation) => {
        this.operationDisplayName = operation.displayName;
      }),
      shareReplay(1),
    );

    combineLatest([this.operation$, pickupDisplayName$])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([operation, pickupDisplayName]) => {
        if (operation.operationData === undefined) {
          return;
        }

        operation.operationData.pickups = operation.operationData.pickups || [];
        operation.operationData.dropoffs =
          operation.operationData.dropoffs || [];
        if (pickupDisplayName) {
          if (
            operation.operationData.pickups.some(
              (pickup) => pickup.displayName === pickupDisplayName,
            )
          ) {
            this.selectedPickupHandoverAlias = pickupDisplayName;
          } else {
            console.warn(
              `Display name '${pickupDisplayName}' could not be retrieved for '${operation.id}' , redirecting.`,
            );
            this.router.navigate(['']);
          }
        }
        if (this.selectedPickupHandoverAlias) {
          operation.operationData.pickups =
            operation.operationData.pickups.filter(
              (p) => p.displayName === this.selectedPickupHandoverAlias,
            );
        }
        this.pickups = operation.operationData.pickups.sort(
          (a, b) =>
            a.displayName?.localeCompare(b.displayName || '', undefined, {
              sensitivity: 'base',
            }) || -1,
        );
        this.dropoffs = operation.operationData.dropoffs.sort(
          (a, b) =>
            a.displayName?.localeCompare(b.displayName || '', undefined, {
              sensitivity: 'base',
            }) || -1,
        );

        this.preferredCountryCodes =
          operation.operationData.preferredCountryCodes;
      });

    this.canEditOrderStatus$ = this.authService.user$.pipe(
      map(hasOrderManagementAccess),
    );

    this.canCreateOrders$ = this.authService.user$.pipe(
      map(hasCreateOrderAccess),
    );

    this.canCancelOrders$ = this.authService.user$.pipe(
      map(hasCancelOrderAccess),
    );

    this.canOpenCloseRobots$ = this.authService.user$.pipe(
      map(hasRobotLockAccess),
    );

    this.enableWatchdog();
  }

  ngAfterViewInit(): void {
    this.orderQueue.sort = this.sort;
    fromEvent(this.textSearchInput.nativeElement, 'keyup')
      .pipe(
        filter((v) => !!v),
        debounceTime(250),
        distinctUntilChanged(),
      )
      .subscribe(() => {
        this.paginator.pageIndex = 0;
        this.refresh$.next(undefined);
      });

    this.subscribeOrders();
  }

  private subscribeOrders() {
    merge(
      visiblePageTimer(0, POLLING_INTERVAL_MS),
      this.paginator.page,
      this.refresh$,
    )
      .pipe(
        takeUntil(this.destroy$),
        switchMap(() => this.operationId$),
        exhaustMap((operationId) => {
          const baseQuery = `/orders?operation_id=${operationId}&status=${this.orderStatusFilter}&per_page=${this.paginator.pageSize}&page=${this.paginator.pageIndex}`;
          const textSearch = this.textSearchInput.nativeElement.value;
          const query =
            baseQuery + (textSearch ? `&text_match=${textSearch}` : '');
          return combineLatest([
            this.backendService.getWithHeader(query).pipe(
              this.errorService.handleStreamErrors(
                'Can not get updated operation state',
              ),
              map((data: HttpResponse<Order[]> | undefined) => {
                if (data === undefined) {
                  return [];
                }

                // Only refresh the result length if there is new data. In case of rate
                // limit errors, we do not want to reset the paginator to zero, as that
                // would prevent users from re-triggering requests.
                this.resultsLength = Number(data.headers.get('x-total-count'));
                return data.body ?? [];
              }),
            ),
            this.backendService
              .get(`/robots?assigned_operation_id=${operationId}`)
              .pipe(
                map((x) => vParsePretty(v.array(Robot), x)),
                this.errorService.handleStreamErrors(
                  'Can not get updated robots status',
                ),
              ),
          ]);
        }),
      )
      .subscribe(([orders, robots]) => {
        this.updateState(orders, robots);
      });
  }

  private enableWatchdog() {
    let errorSnackBar: MatSnackBarRef<SimpleSnackBar> | null = null;
    this.watchdog.problemDetected$
      .pipe(takeUntil(this.destroy$))
      .subscribe((isProblem) => {
        if (isProblem) {
          errorSnackBar = this.snackBar.open(
            'Page update failed, please reload.',
            'reload',
          );
          errorSnackBar.onAction().subscribe(() => {
            window.location.reload(
              // @ts-expect-error for Firefox
              true,
            );
          });
        } else {
          errorSnackBar?.dismiss();
        }
      });
    this.watchdog.start();
  }

  ngOnDestroy() {
    this.destroy$.next(undefined);
    this.watchdog.destroy();
  }

  async logout() {
    await this.authService.logout();
    if (environment.keycloak_only) {
      this.keycloak.login();
    } else {
      this.router.navigate(['/login']);
    }
  }

  openCompartmentForOrder(orderId: string) {
    this.backendService
      .post(`/orders/${orderId}/open-compartment`, {})
      .pipe(retry(2))
      .subscribe();
  }

  onOpenTrackingLink(trackingCode: string) {
    window.open('/orders/' + `${trackingCode}`, '_blank');
  }

  async editOrder({ order }: OrderTableRow) {
    await firstValueFrom(
      this.dialog
        .open(OrderManagementDialogComponent, { data: { order } })
        .afterOpened(),
    );
    this.refresh$.next();
  }

  onOrderStatusFilterChanged() {
    this.paginator.pageIndex = 0;
    this.refresh$.next(undefined);
  }

  onRobotFilterChanged() {
    this.filteredRobotInfos = this.robotInfos.filter(
      (robotInfo) =>
        !this.robotFilter ||
        robotInfo.hasOrders === (this.robotFilter === 'robots-with-order'),
    );
    this.robots = this.filteredRobotInfos
      .filter(
        (robotInfo) =>
          !robotInfo.robot.arrivedAtStop || robotInfo.robot.readyForOrders,
      )
      .map((robotInfo) => robotInfo.robot);
  }

  async cancelOrder({ order }: OrderTableRow) {
    await firstValueFrom(
      this.dialog
        .open<
          CancelOrderDialogComponent,
          CancelOrderDialogData,
          CancelOrderDialogOutput
        >(CancelOrderDialogComponent, {
          data: { orderId: order.id },
          width: '100%',
        })
        .afterClosed(),
    );
    this.refresh$.next();
  }

  completeCurrentHandovers(robotInfo: RobotInfo) {
    this.backendService
      .post(`/orders/complete-handovers/${robotInfo.robotId}`, {})
      .pipe(retry(5))
      .subscribe(() => {
        this.refresh$.next(undefined);
      });
  }

  async openCreateOrderDialog() {
    const operationId = await firstValueFrom(this.operationId$);
    await firstValueFrom(
      this.dialog
        .open(CreateOrderDialogComponent, {
          position: { top: '100px' },
          minWidth: '500px',
          maxWidth: '95vw',
          height: 'fit-content',
          maxHeight: '95vh',
          data: {
            useExternalId: true,
            usePhoneNumber: true,
            operationId: operationId,
          } as CreateOrderDialogData,
        })
        .afterClosed(),
    );
    this.refresh$.next();
  }

  getOperationDisplayName() {
    return this.operationId$.pipe(
      map((operationId) => {
        if (!this.operationDisplayName) {
          return operationId;
        }
        if (this.selectedPickupHandoverAlias) {
          return (
            this.selectedPickupHandoverAlias +
            ' at ' +
            this.operationDisplayName
          );
        }
        return this.operationDisplayName;
      }),
    );
  }

  showOrderDetails({ order }: OrderTableRow) {
    this.orderDialog.open(OrderDetailsDialogComponent, {
      data: order,
    });
  }

  private updateOrders(orders: Order[]) {
    this.orders = orders;
    this.orderQueue.data = orders
      .filter(
        (order) => order.handovers[1]?.handoverType === HandoverType.DROPOFF,
      )
      .sort(
        (order1, order2) =>
          new Date(order1.handovers[0]?.estimatedArrivalTime!).getTime() -
          new Date(order2.handovers[0]?.estimatedArrivalTime!).getTime(),
      )
      .map(orderToTableRow);
  }

  private updateRobotInfo(
    robotMap: Map<string, Robot>,
    robotInfos: RobotInfo[],
    orders: Order[],
  ) {
    for (const robotInfo of robotInfos) {
      const robot = robotMap.get(robotInfo.robotId)!;

      robotInfo.updateRobot(robot, orders);
    }
  }

  private createNewRobotInfos(
    robotMap: Map<string, Robot>,
    existingRobotInfos: RobotInfo[],
  ) {
    for (const robotInfo of existingRobotInfos) {
      robotMap.delete(robotInfo.robotId);
    }

    return Array.from(robotMap.values()).map(
      (newRobot) => new RobotInfo(newRobot),
    );
  }

  private joinRobotInfos(
    oldRobotInfos: RobotInfo[],
    newRobotInfos: RobotInfo[],
  ) {
    const robotInfos = [...oldRobotInfos, ...newRobotInfos];

    robotInfos.sort((a, b) => {
      if (
        (a.robotReadyForOrders && b.robotReadyForOrders) ||
        (!a.robotReadyForOrders && !b.robotReadyForOrders)
      ) {
        return (a.name ?? '').localeCompare(b.name, 'en', {
          numeric: true,
        });
      }
      if (a.robotReadyForOrders) {
        return -1;
      }
      return 1;
    });
    return robotInfos;
  }

  private updateState(orders: Order[], robots: Robot[]) {
    this.updateOrders(orders);

    const newDisplayedColumns = [...this.minimumDisplayedColumns];
    if (orders.some((order) => order.displayName)) {
      newDisplayedColumns.splice(2, 0, 'displayName');
    }
    if (orders.some((order) => order.communicationLog.length)) {
      newDisplayedColumns.splice(2, 0, 'communication');
    }
    this.displayedColumns = newDisplayedColumns;

    const robotMap = new Map(robots.map((robot) => [robot.id, robot]));

    const robotInfoToUpdate = this.robotInfos.filter((robotInfo) =>
      robotMap.has(robotInfo.robotId),
    );

    this.updateRobotInfo(robotMap, robotInfoToUpdate, orders);

    const newRobotInfos = this.createNewRobotInfos(robotMap, robotInfoToUpdate);
    this.updateRobotInfo(robotMap, newRobotInfos, orders);

    this.robotInfos = this.joinRobotInfos(robotInfoToUpdate, newRobotInfos);
    this.robotsReady = this.robotInfos.filter(
      (a) => a.robotReadyForOrders,
    ).length;
    this.robotsCount = this.robotInfos.length;

    const robotsAvailable = Boolean(
      this.robotInfos.find((robotInfo) => robotInfo.robot.readyForOrders),
    );
    const isNotInErrorState = !this.errorService.isInErrorState();

    this.readyForOrders = robotsAvailable && isNotInErrorState;

    this.onRobotFilterChanged();

    this.watchdog.reset();
  }

  async openRobotCompartmentDialog(robotInfo: RobotInfo) {
    await firstValueFrom(
      this.dialog
        .open(CompartmentsDialogComponent, {
          data: { robotId: robotInfo.robotId },
        })
        .afterClosed(),
    );
    this.refresh$.next();
  }

  onZoomPanChange(zoomEvent: ZoomPanTriggerEvent) {
    if (zoomEvent.triggerId !== this.focusItemId) {
      this.focusItemId = '';
    }
  }

  showRobotRoute(robotId: string) {
    this.highlightItemId = robotId;
  }

  zoomToRobot(robotId: string) {
    this.focusItemId = robotId;
  }

  hideRobotRoute() {
    this.highlightItemId = '';
  }

  showOrderOnMap(orderId: string) {
    this.highlightItemId = orderId;
  }

  hideOrderOnMap() {
    this.highlightItemId = '';
  }

  trackByRowId(_: number, row: OrderTableRow) {
    return row.id;
  }
}
