import {
  AfterContentInit,
  Component,
  inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { Observable, firstValueFrom } from 'rxjs';
import { map, take, takeUntil } from 'rxjs/operators';
import { HazardLightsState } from '@/app/core/robots-service/backend/robot.dto';

import { MatDialog } from '@angular/material/dialog';
import { AuthService } from '@/app/core/auth.service';
import { shiftPressed$ } from '@/utils/shift-pressed';
import { SupervisionSettingsService } from '../supervision-settings/supervision-settings.service';
import {
  AttentionDetectionService,
  AttentionSource,
} from '../attention-detection.service';
import {
  HIGH_QUALITY_VIDEO_CONFIG,
  SURROUND_VIEW_VIDEO_CONFIG,
} from '@/app/core/robots-service/webrtc/video-stream';
import { UserSessionService } from '@/app/core/user-session/user-session.service';
import { UserSessionEventTrackingService } from '@/app/core/user-session/user-session-event-tracking.service';
import { UserSessionInteractionEventName } from '@/app/core/user-session/user-session-interaction-events';
import { UserSessionSystemEventName } from '@/app/core/user-session/user-session-system-events';
import { SkipRobotDialogComponent } from './skip-robot-dialog.component';
import { RobotSupervisionComponent } from './robot-supervision.component';
import { Router } from '@angular/router';
import { RobotControlComponent } from '@/app/robots/robot-operation/robot-control/robot-control.component';
import { RobotControlPanelComponent } from '@/app/core/robot-control-panel/robot-control-panel.component';
import { MatMiniFabButton } from '@angular/material/button';
import { MatTooltip } from '@angular/material/tooltip';
import { MatIcon } from '@angular/material/icon';
import { AsyncPipe } from '@angular/common';
import { MatMenuItem } from '@angular/material/menu';

@Component({
  selector: 'app-mini-slot-robot-supervision',
  templateUrl: './mini-slot-robot-supervision.component.html',
  styleUrl: './mini-slot-robot-supervision.component.sass',
  host: {
    '(mouseenter)': 'onMouseIn()',
    '(mouseleave)': 'onMouseOut()',
    '[class.needs-attention]': 'requireAttention()',
  },
  providers: [AttentionDetectionService],
  imports: [
    AsyncPipe,
    MatIcon,
    MatMenuItem,
    MatMiniFabButton,
    MatTooltip,
    RobotControlComponent,
    RobotControlPanelComponent,
  ],
})
export class MiniSlotRobotSupervisionComponent
  extends RobotSupervisionComponent
  implements OnDestroy, OnInit, AfterContentInit, OnChanges
{
  private attentionService = inject(AttentionDetectionService);

  @Input()
  slotIndex?: number;

  isMouseHovering = false;

  requireAttention = toSignal(this.attentionService.state$);

  readonly HAZARD_LIGHTS_STATES = Object.values(HazardLightsState);
  showFullScreenPreview$!: Observable<boolean>;

  constructor(
    private supervisionSettingService: SupervisionSettingsService,
    router: Router,
    authService: AuthService,
    dialog: MatDialog,
    userSessionService: UserSessionService,
    userInteractionsTrackingService: UserSessionEventTrackingService,
  ) {
    super(
      router,
      authService,
      dialog,
      userSessionService,
      userInteractionsTrackingService,
    );
  }

  override async ngOnInit() {
    this.robotCommunication.blockedRobotIsReadyForFinalization$
      .pipe(takeUntil(this._destroy$))
      .subscribe(async (readyToBeFinalized) => {
        try {
          if (readyToBeFinalized) {
            this.userInteractionsTrackingService.trackSystemEvent(
              UserSessionSystemEventName.BLOCKED_ROBOT_UNASSIGNED,
              { robotId: this.robotCommunication.robotId },
            );
            await this.removeRobotSlot();
          }
        } catch (e) {
          console.error('Failed to disable slot', e);
        }
      });

    this.showFullScreenPreview$ = shiftPressed$.pipe(
      map((shiftPressed) => {
        return shiftPressed && this.isMouseHovering;
      }),
    );

    this.showFullScreenPreview$
      .pipe(takeUntil(this._destroy$), takeUntil(this._unsubscribe$))
      .subscribe((fullscreen) => {
        const config = fullscreen
          ? SURROUND_VIEW_VIDEO_CONFIG
          : HIGH_QUALITY_VIDEO_CONFIG;
        this.robotCommunication.sendVideoConfiguration(config);
      });

    this.robotCommunication.robotState$
      .pipe(takeUntil(this._destroy$), takeUntil(this._unsubscribe$))
      .subscribe({
        next: ({ stopState }) => {
          this.attentionService.request(
            'robot-blocked',
            () => stopState?.isBlocked === true,
          );
        },
        complete: () => {
          this.attentionService.clear('robot-blocked');
        },
      });

    await super.ngOnInit();
  }

  onMouseIn() {
    this.robotCommunication.sendVideoQualityRequest(undefined, true);
    this.isMouseHovering = true;
  }

  onMouseOut() {
    this.robotCommunication.sendVideoQualityRequest(undefined, false);
    this.isMouseHovering = false;
  }

  private async removeRobotSlot() {
    const slotIndex = this.slotIndex;
    if (slotIndex !== undefined) {
      await this.robotCommunication.finalize();
      this.userSessionService.unassignRobots(
        [this.robotCommunication.robotId],
        true,
      );
      this.supervisionSettingService.setRobotSlotsByIndex(false, slotIndex);
    }
  }

  async clickRemoveRobot() {
    await this.removeRobotSlot();
    this.userInteractionsTrackingService.trackInteractionEvent(
      UserSessionInteractionEventName.DISABLE_SUPERVISION_SLOT_TRIGGERED,
      {
        robotId: this.robotCommunication.robotId,
      },
    );
  }

  async skipRobot() {
    this.userInteractionsTrackingService.trackInteractionEvent(
      UserSessionInteractionEventName.ROBOT_SKIP_DIALOG_OPEN,
      {
        robotId: this.robotCommunication.robotId,
      },
    );
    this.dialog
      .open(SkipRobotDialogComponent, {
        data: {
          message:
            'Please, make sure that all robot issues are reported before skipping a robot',
        },
      })
      .afterClosed()
      .pipe(take(1))
      .subscribe(async ({ skip, reason }) => {
        if (skip) {
          const routeCorridorConfirmationState = await firstValueFrom(
            this.robotCommunication.routeCorridorConfirmationState$,
          );
          this.userInteractionsTrackingService.trackInteractionEvent(
            UserSessionInteractionEventName.ROBOT_SKIP_TRIGGERED,
            {
              robotId: this.robotCommunication.robotId,
              skipReason: reason,
              distanceToConfirmation:
                routeCorridorConfirmationState.distanceToConfirmation,
            },
          );
          await this.robotCommunication.finalize();
          this.userSessionService.unassignRobots(
            [this.robotCommunication.robotId],
            true,
          );
        }
      });
  }

  handleAttentionRequest(source: AttentionSource, needsAttention: boolean) {
    needsAttention
      ? this.attentionService.request(source, () => true)
      : this.attentionService.clear(source);
  }

  protected override isHighQualityVideo() {
    return this.isMouseHovering;
  }
}
