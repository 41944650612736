import { Component, Input } from '@angular/core';
import { combineLatest, EMPTY, merge, Observable, of, map } from 'rxjs';
import { BackendService } from '@/app/core/backend.service';
import { Operation } from '../operations/operation';

import { Robot } from '@/app/core/robots-service/backend/robot.dto';
import { visiblePageTimer } from '@/utils/page-visibility';
import { filter, exhaustMap, catchError } from 'rxjs/operators';
import { Order } from '../core/order/order';
import { ElementType, RobotQueueEdge } from '@cartken/map-types';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  ConfirmationDialogComponent,
  ConfirmationDialogData,
} from '../core/confirmation-dialog/confirmation-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { EmergencyStopActionService } from './emergency-stop-action.service';
import { v } from '@/valibot';
import { MatIcon } from '@angular/material/icon';
import { MatButton, MatIconButton } from '@angular/material/button';
import { RouterLink } from '@angular/router';
import { NgClass } from '@angular/common';
import { OperationRobotsStatsComponent } from './operation-robots-stats.component';
import { OperationOrdersStatsComponent } from './operation-orders-stats.component';
import { WaitingQueueManagementComponent } from './waiting-queue-management/waiting-queue-management.component';
import { CAsyncPipe } from '@/utils/c-async-pipe';
import { RobotsBackendService } from '@/app/core/robots-service/robots-backend.service';
import { OperationsService } from '../core/operations-service';

const DATA_POLLING_INTERVAL_MILLIS = 5000;
const OPERATION_POLLING_INTERVAL_MILLIS = 10 * 1000;

const MILLIS_IN_HOUR = 1000 * 60 * 60;

function lastHourIsoTime() {
  return new Date(Date.now() - MILLIS_IN_HOUR).toISOString();
}

@Component({
  selector: 'app-operation-overview',
  templateUrl: './operation-overview.component.html',
  styleUrl: './operation-overview.component.sass',
  imports: [
    MatIcon,
    MatButton,
    RouterLink,
    NgClass,
    MatIconButton,
    OperationRobotsStatsComponent,
    OperationOrdersStatsComponent,
    WaitingQueueManagementComponent,
    CAsyncPipe,
  ],
})
export class OperationOverviewComponent {
  robots$: Observable<Robot[]>;
  orders$: Observable<Order[]>;
  finishedOrders$: Observable<Order[]>;
  activeWaitingQueueNames$: Observable<string[]>;
  availableWaitingQueues$: Observable<RobotQueueEdge[]>;
  storageMapElement$: Observable<RobotQueueEdge | undefined>;

  displayName = '';
  operationId = '';
  rejectedOrdersWarningThreshold = 0.1;
  emergencyStopActive = false;

  @Input()
  set operation(operation: Operation) {
    this.operationId = operation.id;
    this.displayName = operation.displayName ?? this.displayName;
    if (operation.operationData?.rejectedOrderWarningThreshold) {
      this.rejectedOrdersWarningThreshold =
        operation.operationData.rejectedOrderWarningThreshold;
    }
    this.emergencyStopActive = operation.emergencyStopActive ?? false;
  }

  constructor(
    private backendService: BackendService,
    private operationsService: OperationsService,
    private robotBackendService: RobotsBackendService,
    private emergencyStopActionService: EmergencyStopActionService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
  ) {
    this.robots$ = merge(
      of([]),
      visiblePageTimer(0, DATA_POLLING_INTERVAL_MILLIS * 10).pipe(
        exhaustMap(() =>
          this.robotBackendService.getRobotsAssignedToOperation(
            this.operationId,
          ),
        ),
      ),
    );

    this.orders$ = merge(
      of([]),
      visiblePageTimer(0, DATA_POLLING_INTERVAL_MILLIS).pipe(
        exhaustMap(() =>
          this.backendService.get<Order[]>(
            `/orders?operation_id=${this.operationId}&status=active&bulk=true`,
          ),
        ),
      ),
    );

    this.finishedOrders$ = merge(
      of([]),
      visiblePageTimer(0, DATA_POLLING_INTERVAL_MILLIS).pipe(
        exhaustMap(() =>
          this.backendService.get<Order[]>(
            `/orders?operation_id=${
              this.operationId
            }&status=final&bulk=true&after=${lastHourIsoTime()}`,
          ),
        ),
      ),
    );

    const operationData$ = visiblePageTimer(
      0,
      OPERATION_POLLING_INTERVAL_MILLIS,
    ).pipe(
      exhaustMap(() =>
        this.operationsService
          .getOperationById(this.operationId)
          .pipe(catchError(() => EMPTY)),
      ),
    );

    const queuesInOperationRegion$ = operationData$.pipe(
      map((operationData) => operationData?.operationRegion),
      filter((operationRegion) => operationRegion !== undefined),
      exhaustMap((operationRegion) => {
        const requestPath = `/map?element-types=${ElementType.ROBOT_QUEUE_EDGE}`;
        const boundingPolygon = operationRegion?.coordinates;
        const boundsQuery = boundingPolygon?.length
          ? `&region-polygon=${JSON.stringify(boundingPolygon)}`
          : '';
        return this.backendService.get(requestPath + boundsQuery).pipe(
          map((result) => v.parse(v.array(RobotQueueEdge), result)),
          catchError(() => of([])),
        );
      }),
    );

    this.availableWaitingQueues$ = merge(of([]), queuesInOperationRegion$).pipe(
      map((queues) =>
        queues.filter((queue) => {
          const props = queue.properties;
          return (
            (props?.queueSlotPriorities?.length ?? 0) > 0 &&
            props?.names?.at(0)?.includes('waiting')
          );
        }),
      ),
    );

    const orderOperationData$ = operationData$.pipe(
      map((operation) => operation.operationData),
      filter(
        (
          operationData,
        ): operationData is Exclude<typeof operationData, undefined> =>
          operationData !== undefined,
      ),
    );

    this.activeWaitingQueueNames$ = merge(
      of([]),
      orderOperationData$.pipe(
        map((operation) => (operation.waitingQueues ?? []).map((q) => q.name)),
      ),
    );

    this.storageMapElement$ = combineLatest(
      [orderOperationData$, queuesInOperationRegion$],
      (operation, robotQueues) => {
        const storageLocationId = operation.storageLocationId;
        if (storageLocationId === undefined) {
          return;
        }
        return robotQueues.find((queue) => {
          const names = queue.properties.names ?? [];
          return names.some((name) => name === storageLocationId);
        });
      },
    );
  }

  onEmergencyButtonClicked() {
    if (this.emergencyStopActive) {
      this.deactivateEmergencyStop();
      return;
    }
    this.activateEmergencyStop();
  }

  activateEmergencyStop() {
    this.dialog
      .open<ConfirmationDialogComponent, ConfirmationDialogData>(
        ConfirmationDialogComponent,
        {
          data: {
            message: 'Activate emergency stop?',
          },
          position: { top: '10%' },
        },
      )
      .afterClosed()
      .subscribe(async (isConfirmed) => {
        if (!isConfirmed) {
          return;
        }
        this.emergencyStopActionService
          .activate(this.operationId)
          .subscribe(() => {
            this.emergencyStopActive = true;
            this.snackBar.open('Emergency Stop Activated', undefined, {
              verticalPosition: 'top',
              duration: 2000,
            });
          });
      });
  }

  deactivateEmergencyStop() {
    this.dialog
      .open<ConfirmationDialogComponent, ConfirmationDialogData>(
        ConfirmationDialogComponent,
        {
          data: {
            message: 'Deactivate emergency stop?',
          },
          position: { top: '10%' },
        },
      )
      .afterClosed()
      .subscribe(async (isConfirmed) => {
        if (!isConfirmed) {
          return;
        }
        this.emergencyStopActionService
          .deactivate(this.operationId)
          .subscribe(() => {
            this.emergencyStopActive = false;
            this.snackBar.open('Emergency Stop Deactivated', undefined, {
              verticalPosition: 'top',
              duration: 2000,
            });
          });
      });
  }
}
